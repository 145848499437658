import { computed, ref, watch } from 'vue'

import type { DefaultHttpRequestBody, HttpEndpoint } from '@backmarket/http-api'
import { postOrderReviewForm } from '@backmarket/http-api/src/api-specs-reviews/order-review-form'
import type { ReviewCollectionPostBody } from '@backmarket/http-api/src/api-specs-reviews/types/review-form'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { objectKeys } from '@backmarket/utils/object/objectKeys'

import type { ReviewForm, ReviewFormQuestion } from '../models/review-form'

import { useReviewCollectionSource } from './useReviewCollectionSource'

// TODO: improve typing
const buildApiValue = (
  question: ReviewFormQuestion,
  value: string | number,
) => {
  if (question.type === 'rating_bar') return { rate: value }
  if (question.type === 'textarea') return { comment: value }

  return { todo: true }
}

function useMutation<T = unknown, B = DefaultHttpRequestBody>(
  endpoint: HttpEndpoint<T, B>,
) {
  const pending = ref(false)
  const error = ref()
  const data = ref<T>()

  async function execute(options: Parameters<typeof $httpFetch<T, B>>[1]) {
    pending.value = true
    try {
      const res = await $httpFetch(endpoint, options)
      data.value = res
    } catch (err) {
      error.value = err
    } finally {
      pending.value = false
    }
  }

  return { pending, error, data, execute }
}

const NEGATIVE_REVIEW_AVG_RATE_MAX_BOUND = 3
function buildBody(
  values: ReviewForm,
  questions: ReviewFormQuestion[],
  source: string,
): ReviewCollectionPostBody {
  const answers: ReviewCollectionPostBody['answers'] = []
  objectKeys(values).forEach((identifier) => {
    const question = questions.find((q) => q.identifier === identifier)

    // form values may still hold values from previous steps.
    // In this case, we won't find the associated question and we don't want to add them to the body
    if (!question) return

    const value = buildApiValue(question, values[identifier])

    answers.push({
      identifier,
      value,
    })
  })

  return {
    answers,
    source,
  }
}

export function usePostOrderReviewFormMutation() {
  const source = useReviewCollectionSource().source.value
  const logger = useLogger()

  const { pending, error, execute, data } = useMutation(postOrderReviewForm)

  const { openErrorToast } = useTheToast()

  watch(error, () => {
    logger.error('[ReviewForm] POST', { error: error.value })

    openErrorToast()
  })

  // TODO: check with API if they can return in the GET endpoint
  const averageRate = computed(() => data.value?.averageRate)

  const isPositiveReview = computed(() =>
    averageRate.value
      ? averageRate.value > NEGATIVE_REVIEW_AVG_RATE_MAX_BOUND
      : false,
  )

  function submit({
    stepValues,
    questions,
    orderlineId,
    authToken,
  }: {
    stepValues: ReviewForm
    questions: ReviewFormQuestion[]
    orderlineId: string | string[]
    authToken?: string
  }) {
    return execute({
      pathParams: { uuid: orderlineId },
      body: buildBody(stepValues, questions, source),
      queryParams: insertIf(!!authToken, { authToken }),
    })
  }

  return {
    pending,
    execute,
    averageRate,
    isPositiveReview,
    submit,
  }
}
